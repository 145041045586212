/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { startTransition, StrictMode, useEffect } from 'react'

import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import {
  init as sentryInit,
  replayIntegration,
  browserTracingIntegration,
} from '@sentry/remix'
import { hydrateRoot } from 'react-dom/client'

sentryInit({
  dsn: 'https://e1c7f4c9d6e1d9321cfd64d1ac9b4de5@o4506861024837632.ingest.us.sentry.io/4507778907373568',
  integrations: [
    browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    // Replay is only available in the client
    replayIntegration(),
  ],

  // Turn off trace sampling, we're not using it
  tracesSampleRate: 0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Disable Sentry when developing locally
  enabled:
    !window.location.hostname.startsWith('127.0.0.1') &&
    !window.location.hostname.startsWith('localhost'),
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
